.title-tagline {
  margin-top: 13%;
  /* font-weight: 600; */
  /* margin-left: 10%; */
}

.landing-img {
  width: 475px;
  height: 450px;
  /* margin-right: 10%; */
  margin-top: 13%;
  border-style: solid;
  border-width: 2px;
  border-color: #6272a4;
}

@media screen and (max-width: 1180px) {
  .landing-img {
    width: 325px;
    height: 300px;
  }
}
